import { ClientService } from "../client-service/client-service";

/** This script assumes it's being run on /app only, no other pages */
(async () => {
    const clientService = new ClientService();
    const doDisable = clientService.getConfig().body.appBodyScrollDisabled;
    if (doDisable) {
        document.addEventListener("DOMContentLoaded", () => {
            document.body.style.overflow = "hidden";
            /*(document.querySelector("#menu-game-container")! as HTMLElement)
                .style.overflow = "hidden";*/
        });
    }

    console.log("[DisableBodyScroll-Script] disabling scroll?", doDisable);
})();